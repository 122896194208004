import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const NewIndex = () => {
  return (
    <Layout>
      <SEO title="Главная" />
      index
    </Layout>
  );
};

export default NewIndex;
